<template>
  <div>
    <div class="a">
      <img class="one" src="../../../assets/img/WAP产品分类2-皇室贵族_02.jpg" />
    </div>
     <div class="b">
      <img class="one" src="../../../assets/img/WAP产品分类2-皇室贵族_03.jpg" />
    </div>
     <div class="c">
      <img class="one" src="../../../assets/img/WAP产品分类2-皇室贵族_04.jpg" />
    </div>
     <div class="d">
      <img class="one" src="../../../assets/img/WAP产品分类2-皇室贵族_05.jpg" />
    </div>
     <div class="e">
      <img class="one" src="../../../assets/img/WAP产品分类2-皇室贵族_06.jpg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.e{
    height: 478px;
}
.d{
    height: 591px;
}
.c{
    height: 523px;
}
.a{
    height: 170px;
}
.b{
    height: 240px;
}
</style>